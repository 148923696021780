import React from "react";
import { Row, Col } from "antd";

const TwoSidedBlock = ({
  title,
  description,
  children,
  imageSrc,
  alignment = "left",
  use_gradient = true,
  gutter = 0,
}) => {
  const error = '`alignment` must be "left" or "right"!';

  if (!["left", "right"].includes(alignment)) throw error;

  let blockOrder = alignment === "right" ? [0, 1] : [1, 0];

  const imageGradient = use_gradient
    ? {
        maskImage:
          "linear-gradient(to " +
          alignment +
          ", rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 60%)",
        WebkitMaskImage:
          "linear-gradient(to " +
          alignment +
          ", rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 60%)",
      }
    : {};

  return (
    <Row gutter={gutter}>
      <Col
        md={{ order: 0 }}
        lg={{ span: 12, order: blockOrder[0] }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div>
          {title && <h3 style={{ textTransform: "uppercase" }}>{title}</h3>}
          {description && <p>{description}</p>}
          {children}
        </div>
      </Col>
      <Col md={{ order: 1 }} lg={{ span: 12, order: blockOrder[1] }}>
        <img
          src={imageSrc}
          alt="logo"
          style={{ maxWidth: "100%", ...imageGradient }}
          className={"rouded-corners"}
        />
      </Col>
    </Row>
  );
};

export default TwoSidedBlock;
