import React, { useState, useEffect } from "react";
import { Button, Card, Col, List, Row, Checkbox, Timeline } from "antd";


import {
  LinkedinFilled,
  GithubOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  CalendarOutlined,
  TeamOutlined,
  ReadOutlined,
  NotificationOutlined
} from "@ant-design/icons";

import { IconCard, PublicationCard } from "components/cards";
import { Separator } from "components/common";
import { ScholarIcon, XIcon } from "components/Icons";

import logo from "assets/imgs/avatar-2.jpg";
import publicationsData from "assets/data/publications.json";
import newsData from "assets/data/news.json";

const { Meta } = Card;

// Styles

const gutter = [
  { xs: 8, sm: 16, md: 32 },
  { xs: 8, sm: 16, md: 32 },
];

const background = {
  background:
    'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4)), url("/img/backgrounds/alps.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  margin: "1.5vh",
  padding: "min(11vh, 200px) 0",
  borderRadius: "15px",
  boxShadow: "inset 0 0 5px black",
};

const button = {
  fontSize: "1.8rem",
  border: "none",
};

const buttonLink = {
  fontSize: "17px",
  border: "none",
};

// Sections

const SocialLinks = () => {
  return (
    <Row justify="center">
      <Col xs={4}>
        <Button href="https://www.linkedin.com/in/giuvecchio/" style={button}>
          <LinkedinFilled />
        </Button>
      </Col>

      <Col xs={4}>
        <Button href="https://github.com/giuvecchio" style={button}>
          <GithubOutlined />
        </Button>
      </Col>

      <Col xs={4}>
        <Button href="https://scholar.google.com/citations?user=TF1JBZYAAAAJ" style={button}>
          <ScholarIcon />
        </Button>
      </Col>

      <Col xs={4}>
        <Button href="https://twitter.com/giuvecchio95" style={button}>
          <XIcon />
        </Button>
      </Col>

      <Col xs={4}>
        <Button href="https://www.youtube.com/channel/UC-Szz5yGx2q4fgQzm3diG0Q/" style={button}>
          <YoutubeOutlined />
        </Button>
      </Col>

      <Col xs={4}>
        <Button href="https://www.instagram.com/giuvecchio/" style={button}>
          <InstagramOutlined />
        </Button>
      </Col>

    </Row>
  );
};

const AboutSection = () => {
  const year = new Date().getFullYear() + 1;

  return (
    <>
      <h2>Who am I?</h2>

      <Separator height={2} />

      <div style={{ hyphens: "manual" }}>
        <p>Hey, I'm <b>Giuseppe</b>, an AI and Computer Graphics researcher.</p>
        <p>My research focuses on <b>Gen AI</b> for <b>graphics</b>, and <b>learning strategies</b> to bridge the gap between simulation and reality.</p>
        <p>When not immersed in pixels, I like to <b>travel</b> the world and <a href="https://www.instagram.com/giuvecchio/">capture moments</a> with my camera.</p>
      </div>

      <Separator height={2} />

      <Row gutter={[32, 32]}>
        <Col md={24} lg={12}>
          <img
            //className="ant-card"
            style={{ width: "100%", borderRadius: 15 }}
            src="/img/pictures/then-now.jpg"
            alt="then-now"
          />
        </Col>
        <Col md={24} lg={12} style={{ textAlign: "justify" }}>
          <p>I'm currently a Research Scientist at <b>Adobe Research</b>, Lyon, focusing on generative models and materials.</p>
          <p>I received my <b>Ph.D. </b> (2023) at the University of Catania, under the supervision of Prof. Concetto Spampinato. </p>
          <p>During my Ph.D., I spent 3 months at the <b>University of Edinburgh</b> under the supervision of Prof. Stefano Albrech as part of the HPC3 program.
            I also did a 6 months internship at <b>Adobe Research</b>, Lyon, working on materials capture.</p>
        </Col>
      </Row>

      <Separator height={2} />
      <Card>
        <Meta
          title="Adobe Research Internship"
          description={
            <>
              I'll be seeking talented PhD students for the <b>{year} Adobe internship program</b>.
              If you're interested in working at Adobe with me, feel free to reach out sharing details about your research interests.
              Internships are typically planned around November of the year preceding it.
            </>
          } />
      </Card>
    </>
  );
};

const NewsSection = () => {
  const [visibleCount, setVisibleCount] = useState(2);
  const ITEMS_PER_PAGE = 2;
  const DEFAULT_VISIBLE_COUNT = 2;

  // Function to show more news
  const showMoreNews = () => {
    setVisibleCount(prevCount => Math.min(prevCount + ITEMS_PER_PAGE, newsData.length));
  };

  // Function to collapse back to initial state
  const showLessNews = () => {
    setVisibleCount(DEFAULT_VISIBLE_COUNT);
  };

  const timeline = (
    <Timeline mode="left" style={{ textAlign: "left", padding: "5px" }}>
      {newsData
        .filter((item) => {
          const [day, month, year] = item.date.split(" ");
          const dateObject = new Date(`${month} ${day}, ${year}`);
          return dateObject <= new Date();
        })
        .slice(0, visibleCount)
        .map((item, index) => {
          let dotIcon;
          if (item.icon === 'team') {
            dotIcon = <TeamOutlined style={{ fontSize: '15px' }} />;
          } else if (item.icon === 'notification') {
            dotIcon = <NotificationOutlined style={{ fontSize: '15px' }} />;
          } else if (item.icon === 'paper') {
            dotIcon = <ReadOutlined style={{ fontSize: '15px' }} />;
          } else {
            dotIcon = null;
          }

          return (
            <Timeline.Item key={index} dot={dotIcon}>
              <List.Item.Meta
                title={item.title}
                description={<div dangerouslySetInnerHTML={{ __html: item.description }} />}
              />
              <hr />
              <span style={{ fontWeight: "lighter" }}><CalendarOutlined />&emsp;{item.date}</span>
            </Timeline.Item>
          );
        })}
    </Timeline>
  );

  return (
    <>
      <Card>
        <Meta
          title="Latest News"
          description={timeline}
        />
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          {visibleCount < newsData.length && (
            <Button 
              type="link" 
              onClick={showMoreNews}
              style={{ marginRight: '8px' }}
            >
              Show More
            </Button>
          )}
          {visibleCount > DEFAULT_VISIBLE_COUNT && (
            <Button 
              type="link" 
              onClick={showLessNews}
            >
              Show Less
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

const InterestsSection = () => {
  return (
    <>
      <h2>Research Interests</h2>
      <Separator height={1} />

      <Row gutter={gutter} justify="center">
        <IconCard
          title="GenAI"
          description="Learning based generative models for images, materials, 3D and more."
          icon="/img/icons/gen_ai.svg"
          link=""
        />

        <IconCard
          title="Computer Graphics"
          description="Solving graphics problems using using deep learning."
          icon="/img/icons/mat_gen.svg"
          link=""
        />

        <IconCard
          title="Sim-to-Real"
          description="Learning strategies to bridge the gap between simulation and reality."
          icon="/img/icons/gaming.svg"
        />
      </Row>
    </>
  );
};

const PublicationSection = ({ publications, onChange }) => {
  const [visibleCount, setVisibleCount] = useState(4); // Show 4 publications by default
  const DEFAULT_VISIBLE_COUNT = 4;

  const allKeywords = Array.from(
    new Set(publicationsData.flatMap(publication => publication.keywords))
  ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  // Function to toggle publications visibility
  const togglePublications = () => {
    if (visibleCount === DEFAULT_VISIBLE_COUNT) {
      setVisibleCount(publications.length);
    } else {
      setVisibleCount(DEFAULT_VISIBLE_COUNT);
    }
  };

  return (
    <>
      <h2>Publications</h2>
      <Separator height={1} />

      <Row gutter={[16, 16]} justify="center">
        {allKeywords.map(keyword => (
          <Col key={keyword}>
            <Checkbox onChange={e => onChange(keyword, e.target.checked)}>
              {keyword}
            </Checkbox>
          </Col>
        ))}
      </Row>

      <Separator height={3} />

      <Row gutter={gutter} justify="center" align="middle">
        {publications.slice(0, visibleCount).map((publication, index) => (
          <PublicationCard
            key={index}
            title={publication.title}
            description={publication.description}
            authors={publication.authors}
            icon={publication.icon}
            link={publication.link}
            arxiv={publication.arxiv}
            pdf={publication.pdf}
            huggingface={publication.huggingface}
            github={publication.github}
            youtube={publication.youtube}
            badge={publication.badge}
          />
        ))}
      </Row>

      <Separator height={2} />
      <div style={{ textAlign: "center" }}>
        {publications.length > DEFAULT_VISIBLE_COUNT && (
          <Button 
            type="link"
            onClick={togglePublications}
          >
            {visibleCount === DEFAULT_VISIBLE_COUNT ? 'Show More' : 'Show Less'}
          </Button>
        )}
        {/* <Button type="link" href="https://scholar.google.com/citations?user=TF1JBZYAAAAJ&hl">
          View on Google Scholar
        </Button> */}|
        <Button type="link" href="https://scholar.google.com/citations?user=TF1JBZYAAAAJ">
        More on <ScholarIcon />
        </Button>
      </div>
    </>
  );
};



// const ProjectsSection = () => {
//   return (
//     <>
//       <h2>Projects</h2>
//       <Separator height={1} />

//       <Row gutter={gutter} justify="center" align="middle">
//         <BigIconCard
//           title="StableMaterials"
//           description="Enhancing diversity in PBR material generation with diffusion models and semi-supervised training."
//           icon="/img/thumbnails/stablematerials.png"
//           link="https://gvecchio.com/stablematerials"
//         />

//         <BigIconCard
//           title="MatSynth"
//           description="A modern dataset of 4,000+ ultra-high resolution PBR materials."
//           icon="/img/thumbnails/matsynth.png"
//           link="https://gvecchio.com/matsynth/"
//           badge={"CVPR 2024!"}
//         />

//         <BigIconCard
//           title="ControlMat"
//           description="Diffusion based material capture model conditioned on input photographs."
//           icon="/img/thumbnails/controlmat.png"
//           link="https://www.gvecchio.com/controlmat"
//         />
//       </Row>
//       <Separator height={2} />
//       <div style={{ textAlign: "right" }}>
//         <Button>
//           <Link to="/projects">Explore more projects</Link>
//         </Button>
//       </div>
//     </>
//   );
// };

// Home Body

const Home = () => {
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState(publicationsData);

  useEffect(() => {
    if (selectedKeywords.length === 0) {
      setFilteredPublications(publicationsData);
    } else {
      setFilteredPublications(
        publicationsData.filter(publication =>
          selectedKeywords.every(keyword => publication.keywords.includes(keyword))
        )
      );
    }
  }, [selectedKeywords]);

  const handleKeywordChange = (keyword, checked) => {
    setSelectedKeywords(prev =>
      checked ? [...prev, keyword] : prev.filter(k => k !== keyword)
    );
  };

  return (
    <>
      <div className="slide slide-first dotted">
        <div style={background}>
          <div className="container">
            <Row justify="center" align="middle" gutter={[32, 32]}>
              <Col>
                <img
                  src={logo}
                  alt="avatar"
                  style={{ maxWidth: "min(400px, 70vw)", borderRadius: "50%", padding: "min(40px, 3vw)" }}
                />
              </Col>
              <Col style={{ textAlign: "center" }}>
                <div>
                  <h1 style={{ margin: 0, fontWeight: "600", hyphens: "manual" }}>Giuseppe Vecchio</h1>
                  <h4 style={{ margin: 0 }}>Ph.D. in Computer Vision</h4>
                  <Button href="mailto:gvecchio@adobe.com" style={buttonLink}>gvecchio [at] adobe [dot] com</Button>
                  <Separator height={2} />
                  <SocialLinks />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Separator height={6} />

        <div className="container centered">
          <div>
            <AboutSection />
            <Separator height={3} />
            <NewsSection />
            <Separator height={6} />
            <InterestsSection />
            <Separator height={6} />
            <PublicationSection publications={filteredPublications} onChange={handleKeywordChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
