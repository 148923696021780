import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Collapse, Col, Row } from "antd";

import {
  CaretRightOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import { DeviceSizeView, Separator } from "components/common";

const { Panel } = Collapse;
const { Meta } = Card;

const gutter = [
  { xs: 8, sm: 16, md: 32 },
  { xs: 8, sm: 16, md: 32 },
];

// Components

const ConditionalButton = ({
  url,
  icon = <LinkOutlined />,
  isLink = false,
}) => {
  if (!isLink) return url ? <a href={url}>{icon} DISCOVER MORE</a> : null;
  else return url ? <Link to={url}>{icon} READ MORE</Link> : null;
};

const BigProjectCard = ({ title, description, icon, link }) => {
  const actionsBlock = [];
  if (link) {
    actionsBlock.push(<ConditionalButton url={link} icon={<LinkOutlined />} />);
  }
  const logo = (
    <img
      alt={title}
      src={icon}
      style={{ width: "100%" }}
    />
  );
  return (
    <>
      <Col md={12} lg={8} style={{ width: "100%" }}>
        <DeviceSizeView screen_size="md" mode="gt">
          <Card
            hoverable
            cover={logo}
            actions={actionsBlock}
          >
            <Meta title={title} description={description} />
          </Card>
        </DeviceSizeView>

        <DeviceSizeView screen_size="md" mode="lt">
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            expandIconPosition="right"
          >
            {logo}

            <Panel header={title} key="1">
              <Meta description={description} />
              <Separator />
              <hr style={{ margin: "14px" }} />
              {actionsBlock}
            </Panel>

          </Collapse>
        </DeviceSizeView>
      </Col>
    </>
  );
};

const GlitchText = ({ children }) => {
  return (
    <div className="glitch-container">
      <p class="glitch">
        <span aria-hidden="true">{children}</span>
        {children}
        <span aria-hidden="true">{children}</span>
      </p>
    </div>
  );
};

// Sections

const ProjectsSection = () => {
  return (
    <>
      <GlitchText><h2>Research Projects</h2></GlitchText>
      <Separator height={1} />

      <Row gutter={gutter} justify="center" align="middle">
        <BigProjectCard
          title="StableMaterials"
          description="Enhancing diversity in PBR material generation with diffusion models and semi-supervised training."
          icon="/img/thumbnails/stablematerials.png"
          link="https://gvecchio.com/stablematerials"
        />
        
        <BigProjectCard
          title="MatForger"
          description="Open-source, diffusion based PBR material generation model."
          icon="/img/thumbnails/matforger.png"
          link="https://huggingface.co/gvecchio/MatForger/"
        />

        <BigProjectCard
          title="MatSynth"
          description="A modern dataset of 4,000+ ultra-high resolution PBR materials."
          icon="/img/thumbnails/matsynth.png"
          link="https://www.gvecchio.com/matsynth"
        />

        <BigProjectCard
          title="MatFuse"
          description="A diffusion model for controllable materials generation and editing."
          icon="/img/thumbnails/matfuse.png"
          link="https://gvecchio.com/matfuse/"
        />

        <BigProjectCard
          title="ControlMat"
          description="Diffusion-based material capture model conditioned on input photographs."
          icon="/img/thumbnails/controlmat.png"
          link="https://www.gvecchio.com/controlmat"
        />

        <BigProjectCard
          title="MIDGARD Simulator"
          description="An open-source simulatior for autonomous robot navigation in outdoor unstructured environments."
          icon="/img/thumbnails/midgard.png"
          link="https://www.midgardsim.org/"
        />
      </Row>

      <Separator height={4} />
      <GlitchText><h2>Other Projects</h2></GlitchText>
      <Separator height={1} />

      <Row gutter={gutter} justify="center" align="middle">
        <BigProjectCard
          title="Horny: A Weird Story of Success and Beauty"
          description="The story of Horny, Weird Frames's icon, narretad in a playful and idiosyncratic shortmovie."
          icon="/img/thumbnails/horny.jpg"
          link="https://weirdframes.com/projects/horny"
        />
      </Row>

      <Separator height={2} />
      <div style={{ textAlign: "right" }}>
        <Button>
          <Link to="/">Back home</Link>
        </Button>
      </div>
    </>
  );
};

// Home Body
const Projects = () => {
  return (
    <>
      <div className="slide dotted">
        <div className="container centered">
          <div>
            <ProjectsSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
